<!--
	作者：jyl
	时间：2017-07-26
	描述：管理用户页面
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <!-- <div class="breadcrumb"></div> -->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :model="formInline"
          ref="formInline"
          :inline="true"
          label-position="right"
          label-width="82px"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_ID')" prop="operationId">
                <el-input
                  size="15"
                  v-model.trim="formInline.operationId"
                  placeholder="输入商户ID"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_code')" prop="operationCode">
                <el-input
                  size="15"
                  v-model.trim="formInline.operationCode"
                  placeholder="输入商户编码"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')" style="" prop="operationName">
                <el-input
                  size="15"
                  v-model.trim="formInline.operationName"
                  placeholder="输入商户名称"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')" prop="state">
                <el-select v-model.trim="formInline.state" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="未启用" value="0"></el-option>
                  <el-option label="已启用" value="1"></el-option>
                  <el-option label="停用" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.last_Operator')" prop="managerName">
                <el-input
                  :maxlength="20"
                  size="15"
                  v-model.trim="formInline.managerName"
                  placeholder="请输入操作人"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                v-if="$route.meta.authority.button.query"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.create"></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.create">
            <div class="col_left">
              <router-link :to="{ path: '/pdaTicketConfigDetail?type=add' }">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  v-if="$route.meta.authority.button.create"
                  >{{ $t('button.addto') }}</el-button
                >
              </router-link>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px; text-align: center"
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.state !== 1" command="2">编辑</el-dropdown-item>
                  <el-dropdown-item command="1">查看</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.state !== 1" command="4">启用</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.state !== 1" command="3">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setIndex } from "@/common/js/public.js";
export default {
  name: "pdaTicketConfig",
  data() {
    return {
      total: 0,
      pageSize: 15,
      pageNum: 1,
      isEdit: false,
      loading: false,
      tableCols: [
        {
          prop: "operationId",
          label: "商户ID",
          width: "",
        },

        {
          prop: "operationCode",
          label: "商户编码",
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            return cellValue === 0 ? (
              <span style="color:#F59A23">未启用</span>
            ) : cellValue === 1 ? (
              <span style="color:#95F204">已启用</span>
            ) : (
              <span style="color:#D9001B">停用</span>
            );
          },
        },
        {
          prop: "creatorName",
          label: "最后操作人",
          width: "",
        },
        {
          prop: "updatedTime",
          label: "最后修改时间",
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        operationId: "",
        operationName: "",
        operationCode: "",
        state: "",
        managerName: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs.formInline.resetFields();
    },
    // resetSeret
    onDeleteConfigure({ id }) {
      this.$confirm("确认删除此条小票信息吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get("/acb/2.0/pdaTicketConfigure/delete", {
              data: {
                id,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.searchData();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 操作细则
    handleCommand(command, data) {
      if (command == 1) {
        this.$router.push({
          path: "/pdaTicketConfigDetailSee",
          query: {
            id: data.id,
          },
        });
      } else if (command == 2) {
        this.$router.push({
          path: "/pdaTicketConfigDetail",
          query: {
            id: data.id,
          },
        });
      } else if (command == 3) {
        this.onDeleteConfigure(data);
      } else if (command == 4) {
        this.onEnableConfigure(data);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 处理操作相关动作
    onEnableConfigure(systemData) {
      this.$confirm("启用后的小票配置将同步至PDA，原先的小票配置将被停用。确认启用吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get("/acb/2.0/pdaTicketConfigure/state", {
              data: {
                id: systemData.id,
                state: 1,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "小票配置启用成功!",
              });
              this.searchData();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/pdaTicketConfigure/query", {
          data: Object.assign(this.formInline, {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          }),
        })
        .then((res) => {
          if (!res.value) {
            this.total = 0;
          }
          this.loading = false;
          this.total = (res.value && res.value.total * 1) || 0;
          this.tableData = setIndex(this.pageNum, res.value.list);
        });
    },
    // 提交数据
    submitData() {
      if (this.isEdit) {
        // 修改管理员
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.update();
          } else {
            return false;
          }
        });
      } else {
        // 添加管理员
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.addUser();
          } else {
            return false;
          }
        });
      }
    },
  },
  activated() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
